@import './../../../theme/theme';

.container {
  background-color: $light;
  border-radius: 7px;
  padding: 20px;
  padding-bottom: 0px;
  width: 320px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerCloseIcon {
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.headerCloseIcon:hover {
  cursor: pointer;
}
.headerTitle {
  font-family: $primaryFontFamily;
  font-weight: bold;
  font-size: 27px;
  color: $primaryFontColor;
  margin-left: 20px;
}

.contributionAmount {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  margin-top: 20px;
  text-align: center;
  font-style: italic;
}

.contributionMessage {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  margin-top: 20px;
  text-align: center;
}

.horizontalLine {
  height: 1px;
  background-color: #d5d5d5;
  margin-top: 20px;
}

.thankyouImageContainer {
  display: flex;
  justify-content: center;
}
.thankyouImage {
  background-image: url('/tenants/planet/images/donations/donationCompletion.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 7px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 280px;
  height: 280px;
  align-self: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.tempThankYouImage {
  background-image: url('/tenants/planet/images/donations/donationCompletion.jpg');
  background-position: center;
  background-size: cover;
  width: 2300px;
  height: 2300px;
  align-self: center;
  position: relative;
  display: flex;
  justify-content: center;
}


.tempDonationCount{
  font-size: 130px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  bottom: 300px;
  padding: 0px 80px;
  color: $light;
  font-variant-ligatures: none;
}


.tempDonationTenant{
  font-size: 75px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  position: absolute;
  bottom: 60px;
  color: $light;
  font-variant-ligatures: none;
}

.donationCount {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  bottom: 20px;
  color: $light;
  font-variant-ligatures: none;
}

.donationTenant {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  margin-top: 16px;
  color: $light;
  font-variant-ligatures: none;
}

.pfpLogo {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 36px;
  width: 36px;
  background-color: $light;
  border-radius: 36px;
  padding: 4px;
}

.shareRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -30px;
  width: fit-content;
  left: 50%;
  padding: 5px 10px;
  border-radius: 30px;
  transform: translate(-50%, 0%);
  background-color: $light;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);
}

.shareIcon {
  padding: 4px 4px;
  // background-color: blue;
  border-radius: 30px;
  margin: 0px 5px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
  :hover {
    cursor: pointer;
  }
}
.downloadButton {
  border-radius: 40px;
  height: 48px;
  padding: 12px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: $primaryColor;
  color: $light;
  font-family: $primaryFontFamily;
  font-weight: 600;
  :hover {
    cursor: pointer;
  }
}


@include smTabletView {

.speedDialButton:focus {
  outline: none;
}
  .container {
    width: 412px;
  }
  .thankyouImage {
    width: 372px;
    height: 372px;
  }
}

:export {
  blueishGrey: '#f2f2f7';
}

.thankyouImageHeader {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: $light;
  font-variant-ligatures: none;
  padding-top: 15%;
  white-space: pre-line;
}

.thankyouImageHeader p h1{
  font-size: 34px;
  font-weight: bolder;
}

.tempthankyouImageHeader{
  font-size: 100px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  padding-top: 17%;
  color: $light;
  font-variant-ligatures: none;
  white-space: pre-line;
}

.tempthankyouImageHeader p h1 {
  font-size: 150px;
}


.actionButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.actionButtonsText {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  margin-left: 24px;
  margin-right: 24px;
}
.continueButton {
  background-image: linear-gradient(103deg, #68b030 5%, #007a49 116%);
  border-radius: 18px;
  padding: 8px 40px;
  color: $light;
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
}